import { GameMainContentProps } from "../../../../utils/Interfaces/Game";
import FileItem from "./FileItem";
import { GameMainContentWrapper } from "./styles";

function GameMainContent({ files, activeFolder }: GameMainContentProps) {
  return (
    <GameMainContentWrapper>
      {files[activeFolder]?.map((file: string) => (
        <FileItem file={file} activeFolder={activeFolder} />
      ))}
    </GameMainContentWrapper>
  );
}

export default GameMainContent;
