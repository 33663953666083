import { Box, IconButton, styled } from "@mui/material";
import { COLORS, PALETTE } from "../../assets/theme";

interface AddToCartMinusPlusBoxProps {
  caseCartToBuy: boolean;
}

export const AddToCartMinusPlusBox = styled(Box)<AddToCartMinusPlusBoxProps>`
  color: ${COLORS.black};
  background-color: ${PALETTE.primaryThree};
  padding: 0;
  text-transform: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: ${({ caseCartToBuy }) => !caseCartToBuy && "100%"};
  overflow: hidden;

  @media (min-width: 600px) {
    width: ${({ caseCartToBuy }) => !caseCartToBuy && "260px"};
  }
`;

export const ActionIconButton = styled(IconButton)`
  border-radius: 0;
  height: 40px;
  width: 50px;

  &:hover {
    background-color: ${COLORS.yellowFive};
  }
`;
