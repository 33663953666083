import { useContext, useEffect, useState } from "react";
import BoughtCaseCard from "../BoughtCaseCard";
import { useQuery } from "@tanstack/react-query";
import { GeneralAppDataContext } from "../../utils/GeneralContext";
import {
  BoldSmallText,
  DividerSection,
  MainButton,
  MediumText,
  SecondaryButton,
  SmallText,
} from "../../utils/GlobalStyles";
import { BoughtCasesBox, CasesWrapper, WatchMoreBox } from "./styles";
import { GeneralAppDataContextType } from "../../utils/Interfaces/Global";
import { MyCaseType } from "../../utils/Interfaces/Account";
import { NoOrdersBox } from "../Orders/OrdersTable/styles";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../assets/theme";
import { getData } from "../../utils/endpoints/api";
import SmallLoader from "../loaders/SmallLoader";

function BoughtCases() {
  const { user, setWarningMessage } = useContext(
    GeneralAppDataContext,
  ) as GeneralAppDataContextType;
  const {
    data: myCases,
    error: myCasesError,
    isLoading,
  } = useQuery<MyCaseType[], Error>({
    queryKey: ["myCases"],
    queryFn: () => getData("my-case/list", { userId: user?.id! }),
    enabled: !!user,
  });

  useEffect(() => {
    if (myCasesError) {
      setWarningMessage([
        "error",
        "Houve um erro ao obter os casos comprados. Tenta mais tarde!",
      ]);
    }
  }, [myCasesError]);

  const numberOfItems = 3;
  const [showItems, setShowItems] = useState(numberOfItems);
  const handleShowMore = () => setShowItems(showItems + numberOfItems);

  return (
    <>
      {isLoading ? (
        <SmallLoader />
      ) : (
        <>
          {myCases && myCases.length > 0 ? (
            <BoughtCasesBox>
              <MediumText>
                Estes são os casos comprados com a tua conta
              </MediumText>
              <CasesWrapper>
                {myCases
                  ?.slice(0, showItems)
                  .map((props, key) => <BoughtCaseCard key={key} {...props} />)}
              </CasesWrapper>
              {showItems < myCases.length && (
                <WatchMoreBox mt={3}>
                  <DividerSection />
                  <SecondaryButton onClick={handleShowMore}>
                    <SmallText color={`${COLORS.white} !important`}>
                      Ver mais
                    </SmallText>
                  </SecondaryButton>
                </WatchMoreBox>
              )}
            </BoughtCasesBox>
          ) : (
            <NoOrdersBox>
              <MediumText mt={5}>Ainda não compraste nenhum caso.</MediumText>
              <NavLink to="/cases">
                <MainButton sx={{ marginTop: 2 }}>
                  <BoldSmallText>Comprar agora!</BoldSmallText>
                </MainButton>
              </NavLink>
            </NoOrdersBox>
          )}
        </>
      )}
    </>
  );
}

export default BoughtCases;
