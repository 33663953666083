import { useEffect, useLayoutEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { PrivateRoute } from "./views/PrivateRoute";
import Authentication from "./views/Authentication";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Homepage from "./views/Homepage";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Cases from "./views/CasesShop";
import HowToStart from "./views/HowToStart";
import AboutUs from "./views/AboutUs";
import ShoppingCart from "./components/CheckoutSteps/ShoppingCart";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { GeneralAppDataContext } from "./utils/GeneralContext";
import ContactUs from "./views/ContactUs";
import { fetchUserAttributes } from "aws-amplify/auth";
import CaseGame from "./views/CaseGame";
import BigLoader from "./components/loaders/BigLoader";
import { ContentBox, ContentWrapper, MainContent } from "./utils/GlobalStyles";
import { UserType } from "./utils/Interfaces/Account";
import { CartType, GroupedCartItemType } from "./utils/Interfaces/Cart";
import Account from "./views/Account";
import PaymentMethods from "./views/PaymentMethods";
import Checkout from "./views/Checkout";
import Cookies from "./components/Cookies";
import TermsAndConditions from "./views/TermsAndConditions";
import { groupCartByItem } from "./components/CartItem/helpers";
import { SharedCase } from "./views/SharedCase";
import { getData, postData } from "./utils/endpoints/api";
import WarningSlider from "./components/WarningSlider";
import PrivacyTerms from "./views/PrivacyTerms";
import ReactGA from "react-ga4";
import FacebookPixel from "./utils/FacebookPixel";

Amplify.configure(awsExports);

function App() {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  if (isProduction) {
    ReactGA.initialize("G-E99NXV69FK");
  }
  const [user, setUser] = useState<UserType | undefined>(undefined);
  const [cart, setCart] = useState<CartType>({ totalAmount: 0, items: [] });
  const [warningMessage, setWarningMessage] = useState<[string, string] | null>(
    null
  );
  const [openWarning, setOpenWarning] = useState(false);
  const [groupedCart, setGroupedCart] = useState<GroupedCartItemType[]>([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isCaseGameRoute =
    location.pathname.startsWith("/case-game") ||
    location.pathname.startsWith("/shared-case");
  const {
    data: fetchedCart,
    error: fetchedCartError,
    isLoading: isFetchingCart,
  } = useQuery<CartType, Error>({
    queryKey: ["cart"],
    queryFn: () => getData("cart/items", { userId: user?.id! }),
    enabled: !!user?.id,
  });

  useEffect(() => {
    if (fetchedCartError) {
      setWarningMessage([
        "error",
        "Houve um erro com o carrinho. Tenta mais tarde!",
      ]);
    }
  }, [fetchedCartError]);

  const queryClient = useQueryClient();
  const replaceCartMutation = useMutation({
    mutationFn: ({
      params,
      body,
    }: {
      params: Record<string, string>;
      body: Record<string, any>;
    }) => postData("cart/replace", params, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
    onError: () => {
      setWarningMessage([
        "error",
        "Houve um erro com o carrinho. Tenta mais tarde!",
      ]);
    },
  });

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (fetchedCart) {
      setCart(fetchedCart);
    }
  }, [fetchedCart]);

  useEffect(() => {
    const groupedItems: GroupedCartItemType[] = groupCartByItem(cart.items);
    setGroupedCart(groupedItems);
  }, [cart]);

  let checkAuthState = async () => {
    try {
      const { sub, given_name, family_name, email } =
        await fetchUserAttributes();
      const user: UserType = {
        id: sub,
        firstName: given_name,
        lastName: family_name,
        email: email,
      };

      setUser(user);

      const localCart = localStorage.getItem("cart");
      if (localCart !== null) {
        try {
          await replaceCartMutation.mutateAsync({
            params: { userId: sub! },
            body: JSON.parse(localCart)?.items,
          });
          localStorage.removeItem("cart");
        } catch (error) {
          console.error("Error adding item to cart:", error);
        }
      }
      setIsAuthenticated(true);
      setIsLoading(false);
    } catch (error) {
      setIsAuthenticated(false);
      setUser(undefined);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuthState();
  }, [isAuthenticated, location?.pathname]);

  useEffect(() => {
    if (!user) {
      const cartFromLocalStorage = localStorage.getItem("cart");
      const newCart: CartType = cartFromLocalStorage
        ? JSON.parse(cartFromLocalStorage)
        : { totalAmount: 0, items: [] };
      setCart(newCart);
    }
  }, [user, localStorage.getItem("cart")]);

  const aggregatedLoading =
    isFetchingCart || replaceCartMutation.isPending || isLoading;


  return (
    <>
      <title>Desarquivados</title>
      <Cookies />
      {isProduction && <FacebookPixel />}
      <GeneralAppDataContext.Provider
        value={{
          user,
          setUser,
          isAuthenticated,
          setIsAuthenticated,
          isLoading,
          setIsLoading,
          cart,
          setCart,
          groupedCart,
          setGroupedCart,
          warningMessage,
          setWarningMessage,
        }}
      >
        {aggregatedLoading && <BigLoader />}
        <WarningSlider
          warningMessage={warningMessage}
          setWarningMessage={setWarningMessage}
          openWarning={openWarning}
          setOpenWarning={setOpenWarning}
        />
        <MainContent>
          {!isCaseGameRoute ? <Navbar /> : !isAuthenticated && <Navbar />}
          <ContentWrapper>
            <ContentBox isCaseGameRoute={isCaseGameRoute}>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/*" element={<Navigate replace to="/" />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/cases/*" element={<Cases />} />
                <Route
                  path="/case-game/*"
                  element={
                    <PrivateRoute>
                      <CaseGame />
                    </PrivateRoute>
                  }
                />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/privacy-terms" element={<PrivacyTerms />} />
                <Route path="/how-to-start" element={<HowToStart />} />
                <Route path="/login" element={<Authentication />} />
                <Route path="/payments" element={<PaymentMethods />} />
                <Route
                  path="/checkout/*"
                  element={
                    <PrivateRoute>
                      <Checkout />
                    </PrivateRoute>
                  }
                />
                <Route path="/shared-case/*" element={<SharedCase />} />
                <Route path="/shopping-cart" element={<ShoppingCart />} />
                <Route
                  path="/account"
                  element={
                    <PrivateRoute>
                      <Account />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </ContentBox>
          </ContentWrapper>
          {!isCaseGameRoute ? <Footer /> : !isAuthenticated && <Footer />}
        </MainContent>
      </GeneralAppDataContext.Provider>
    </>
  );
}

export default App;
